import { createStore } from 'redux'

const initialState = {
  sidebarShow: true,
  token: '',
  user:  {},
  permissions: [],
  permissions_key: [],
  modules: [],
  records: []
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
