import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/login/Login'))
const UpdatePassword = React.lazy(() => import('./views/login/UpdatePassword'))
const Page404 = React.lazy(() => import('./views/page404/Page404'))
const Page500 = React.lazy(() => import('./views/page500/Page500'))
const Logout =  React.lazy(() => import('./views/logout/Logout'))
const Black =  React.lazy(() => import('./views/back_friday/Black'))
const StockVtex =  React.lazy(() => import('./views/back_friday/StockVtex'))
// const Welcome =  React.lazy(() => import('./views/welcome/Welcome'))
const TrackerClient =  React.lazy(() => import('./views/tracker/TrackerClient'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/bem-vindo" name="Bem Vindo" element={<Login />} />
            <Route exact path="/login" name="Página de Login" element={<Login />} />
            <Route exact path="/black/painel" name="Black Friday" element={<Black />} />
            <Route exact path="/black/estoque" name="Estoque" element={<StockVtex />} />
            <Route exact path="/atualizar-perfil" name="Página de Atualização" element={<UpdatePassword />} />
            <Route exact path="/logout" name="Página de Logout" element={<Logout />} />
            <Route exact path="/404" name="Erro 404" element={<Page404 />} />
            <Route exact path="/500" name="Erro 500" element={<Page500 />} />
            <Route exact path="/rastrear/:note_key" name="Rastrear Pedido" element={<TrackerClient />} />
            <Route path="*" name="Painel" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
